import {
  Button,
  CardHeader,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material'
import TopLogoCard from '../MainPage/TopLogoCard'
import {
  buttonDisabledStyle,
  buttonSuccessStyle,
  colors,
  standardPadding,
  standardWidthLarger,
} from '../../themes/generalStyles'
import MainCard from '../MainPage/MainCard'
import { useEffect, useState } from 'react'
import useApi from '../../hooks/useApi'
import { fetchVideos, setPartnership } from '../../api/fetch'

export type VideoType = {
  title: string
  url: string
  search_tags: string
  id: number
}

const Partner = () => {
  const [code, setCode] = useState('')
  const [isError, setIsError] = useState(false)
  const [feedbackText, setFeedbackText] = useState('')
  const { data, doFetch, isLoading } = useApi(setPartnership)
  const fetch = () => {
    doFetch({
      data: { coupon: code },
      onError: () => {
        setFeedbackText('Koden er ugyldig')
        setIsError(true)
      },
      onSuccess: () => {
        setIsError(false)
        setCode('')
        setFeedbackText('Koden er aktivert')
      },
    })
  }
  return (
    <MainCard
      cardHeader={
        <CardHeader
          title={
            <Grid container>
              <TopLogoCard sx={{ width: '100%' }} content='Samarbeid med oss' />
            </Grid>
          }
        />
      }
      sx={{
        width: standardWidthLarger,
        marginBottom: 10,
      }}>
      <Grid
        container
        justifyContent='center'
        gap={1}
        sx={{
          paddingLeft: standardPadding,
          paddingRight: standardPadding,
          paddingBottom: standardPadding,
        }}>
        <div>
          <strong>Interessert i samarbeid?</strong> Vi ser alltid etter
          spennende partnerskap med organisasjoner og bedrifter som ønsker å
          bygge verdi sammen med oss. Gjennom et samarbeid kan vi tilby
          spesielle muligheter som er skreddersydd for deres ansatte eller
          medlemmer, og sammen kan vi skape fordeler som gir gjensidig vekst.
          Har dere en avtalekode? Bruk feltet nedenfor for å aktivere deres
          tilgang og oppdage fordelene ved et samarbeid med oss.
        </div>
        <div>
          {!!data ? (
            <h2 style={{ color: colors.main }}>Koden er aktivert!</h2>
          ) : (
            <Grid sx={{ marginTop: 2 }} container direction='column' gap={2}>
              <TextField
                disabled={isLoading}
                value={code}
                onChange={e => setCode(e.target.value)}
                id='standard-basic'
                label='Skriv inn avtalekode'
                variant='outlined'
                error={isError}
                helperText={feedbackText}
              />
              <Button
                onClick={fetch}
                disabled={!code}
                sx={!code ? buttonDisabledStyle : buttonSuccessStyle}>
                {isLoading ? <CircularProgress /> : 'Aktiver'}
              </Button>
            </Grid>
          )}
        </div>
      </Grid>
    </MainCard>
  )
}

export default Partner
