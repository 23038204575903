import { Button, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { buttonSuccessStyle } from '../../themes/generalStyles'
import { useContext } from 'react'
import ModalContext from '../../Contexts/ModalContext'

const WelcomeToPage = () => {
  const { setOpenModal } = useContext(ModalContext)
  return (
    <Grid container direction='column' alignItems='center'>
      <h1>Velkommen til iSteady</h1>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{ marginTop: 0 }}
        direction='column'>
        <div>
          <ul style={{ maxWidth: 500 }}>
            <li>Du har nå opprettet en iSteady-bruker.</li>
            <li>
              Utforsk gjerne nettsiden, men husk at du må aktivere et medlemskap
              for å ta i bruk tjenesten.
            </li>
          </ul>
        </div>
        <div>
          <Button
            sx={{ ...buttonSuccessStyle, textAlign: 'center' }}
            onClick={() => setOpenModal('subscriptions')}>
            Aktiver medlemsskap
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

export default WelcomeToPage
