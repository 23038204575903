import { Box } from '@mui/material'
import ReactPlayer from 'react-player'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { colors } from '../../themes/generalStyles'
import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
} from 'react'
import ScreenScaleContext from '../../Contexts/ScreenScaleContext'
import { VideoType } from './Videos'

const VideoBox = ({
  video,
  invisibleBox = false,
  light,
}: {
  video: VideoType
  invisibleBox?: boolean
  light?: ReactElement
}) => {
  const { smallScreenWidth } = useContext(ScreenScaleContext)
  return (
    <Box
      sx={{ width: '100%', padding: invisibleBox || smallScreenWidth ? 0 : 2 }}>
      {!invisibleBox && (
        <h3 style={{ textAlign: 'center', marginBottom: 10 }}>{video.title}</h3>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 2,
          width: '100%',
          boxShadow: colors.shadow,
          aspectRatio: '16/9',
          backgroundColor: invisibleBox ? undefined : colors.lightGray,
          transition: '0.5s',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}>
        <ReactPlayer
          width={invisibleBox ? '100%' : '98%'}
          height={invisibleBox ? '100%' : '98%'}
          url={video.url}
          controls
          light={light ? light : true}
          playing={true}
          config={{
            vimeo: {
              playerOptions: {
                cc: false,
                responsive: true,
                title: true,
                vimeo_logo: false,
                volume: false,
                transcript: false,
              },
            },
          }}
          playIcon={
            !invisibleBox ? (
              <PlayCircleOutlineIcon
                sx={{ fontSize: 70, color: colors.disabled }}
              />
            ) : undefined
          }
        />
      </Box>
    </Box>
  )
}

export default VideoBox
