import axios, { AxiosRequestConfig } from 'axios'
import settings from '../config'

const BASEURL = settings.backendUrl

type DataType = { [key: string]: any }

export const postInitiateSmsAuth = (
  data: DataType = {},
  headers: AxiosRequestConfig,
): Promise<any> =>
  axios.post(`${BASEURL}/apis/instantiate-auth/`, data, headers)

export const postSmsCode = (
  data: DataType = {},
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/sms-validate/`, data, headers)

export const postJoinOrLeaveWorkout = (
  data: DataType,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/assign-workout/`, data, headers)

export const postNewUser = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => {
  return axios.post(`${BASEURL}/apis/create-new-user/`, data, headers)
}

export const postCreateStripePortal = (
  data = {},
  headers: AxiosRequestConfig,
): Promise<any> =>
  axios.post(`${BASEURL}/apis/create-customer-stripe-portal/`, data, headers)

export const getSubscriptions = (data = {}, headers: AxiosRequestConfig) =>
  axios.get(`${BASEURL}/apis/all-subscriptions/`, headers)

export const getMeetingInfo = (
  data = {},
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/meeting-info/`, data, headers)

export const postCreateSubscription = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> =>
  axios.post(`${BASEURL}/apis/create-subscription/`, data, headers)

export const updateDeleteUser = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/edit-user/`, data, headers)

export const postCalendlyData = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> =>
  axios.post(`${BASEURL}/apis/calendly-bookings/`, data, headers)

export const fetchNewsData = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/news/`, data, headers)

export const fetchWebshop = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/shop/`, data, headers)

export const createShopPortal = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/shop-portal/`, data, headers)

export const checkOrderStatus = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> =>
  axios.post(`${BASEURL}/apis/shop-verification/`, data, headers)

export const shopHistory = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/shop-history/`, data, headers)

export const simpleUserUpdate = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/user-update/`, data, headers)

export const contactUs = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/contact-us/`, data, headers)

export const allSessions = (headers: AxiosRequestConfig): Promise<any> =>
  axios.get(`${BASEURL}/apis/all-sessions/`, headers)

export const fetchFrontNews = (headers: AxiosRequestConfig): Promise<any> =>
  axios.get(`${BASEURL}/apis/front-news/`, headers)

export const onboardMySelf = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/onboard-my-self/`, data, headers)

export const fetchMeetingUrl = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/meeting-url/`, data, headers)

export const fetchDiscount = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/get-discount/`, data, headers)

export const fetchVideos = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/get-videos/`, data, headers)

export const setPartnership = (
  data: any,
  headers: AxiosRequestConfig,
): Promise<any> => axios.post(`${BASEURL}/apis/set-partnership/`, data, headers)
