import {
  buttonSuccessStyle,
  buttonWarningStyle,
  colors,
} from '../themes/generalStyles'
import { Box, Button, CircularProgress, Grid, Tooltip } from '@mui/material'
import { Dispatch, SetStateAction, useContext } from 'react'
import userContext from '../Contexts/UserContext'
import subscriptionState from '../helpers/subscriptionState'
import parseEpochToDate from '../helpers/parseEpochToDate'
import { UseRouteToStripeType } from '../hooks/useRouteToStripe'

const SubscriptionCard = ({
  bindingPeriod,
  id,
  cost,
  rubberBandId = '',
  setNewCustomerView,
  notUsedFreeTrial,
  apis,
  coupon,
}: {
  bindingPeriod: number
  id: string
  cost: number
  rubberBandId?: string
  setNewCustomerView: Dispatch<SetStateAction<string>>
  notUsedFreeTrial: boolean
  apis: UseRouteToStripeType
  coupon: string | undefined
}) => {
  const { loggedIn, userData } = useContext(userContext)
  const { isLoading, buttonOnClick } = apis

  const subState = subscriptionState(userData, id)
  const subscriptionIsActive = ['active', 'cancelled'].includes(subState)
  const disableOther = subState === 'disabeled'
  const buttonStyle = (
    subscriptionIsActive ? buttonWarningStyle : buttonSuccessStyle
  ) as any

  // inject boxshadow into buttonStyle
  buttonStyle['&:hover'] = {
    ...buttonStyle['&:hover'],
    boxShadow: colors.shadow,
    opacity: 0.7,
  }
  const deactivationText =
    subState === 'cancelled'
      ? parseEpochToDate(userData?.subscriptionCancelAt || null)
      : ''
  return (
    <Grid
      container
      justifyContent='center'
      sx={{ width: '100%', fontSize: 23 }}>
      <Box
        sx={{
          boxShadow: colors.shadow,
          borderRadius: 1,
          width: '100%',
          color: colors.darkgrey,
          padding: 2,
        }}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          sx={{ paddingLeft: 5, paddingRight: 5 }}>
          <Grid item sx={{ width: 120 }}>
            {`${bindingPeriod} Måned${bindingPeriod !== 1 ? 'er' : ''}`}
          </Grid>
          <Grid item>
            <Grid container direction='column' alignItems='center'>
              <>
                <div>{cost} kr /mnd</div>
                <div style={{ fontSize: 10 }}>
                  Totalt{' '}
                  <strong>
                    kr {Math.round(cost * bindingPeriod * 100) / 100}
                  </strong>
                </div>
              </>
            </Grid>
          </Grid>
          <Grid item>
            <Tooltip
              arrow
              title={
                disableOther ? (
                  <div style={{ maxWidth: 200 }}>
                    For å melde seg på denne må du først avslutte et annet
                    løpende abonnement.
                  </div>
                ) : (
                  ''
                )
              }>
              <div>
                <Button
                  sx={{ ...buttonStyle, boxShadow: colors.shadow }}
                  disabled={disableOther}
                  size='small'
                  style={{
                    height: 50,
                    width: 200,
                    fontSize: 18,
                  }}
                  variant='contained'
                  onClick={() => {
                    if (loggedIn) {
                      notUsedFreeTrial
                        ? setNewCustomerView(id)
                        : buttonOnClick(
                            subscriptionIsActive,
                            id,
                            rubberBandId,
                            coupon || '',
                          )
                    } else {
                      window.location.href = '/ny-bruker'
                    }
                  }}>
                  {isLoading ? (
                    <CircularProgress size={30} />
                  ) : subscriptionIsActive ? (
                    'Rediger medlemskap'
                  ) : (
                    'Aktiver medlemskap'
                  )}
                </Button>
                {deactivationText && !coupon && (
                  <div
                    style={{
                      fontSize: 12,
                      width: '100%',
                      textAlign: 'center',
                      color: colors.warning,
                    }}>
                    Vil deaktiveres {deactivationText}
                  </div>
                )}
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default SubscriptionCard
