import { CardHeader, CircularProgress, Grid } from '@mui/material'
import TopLogoCard from '../MainPage/TopLogoCard'
import {
  standardPadding,
  standardWidthLarger,
} from '../../themes/generalStyles'
import MainCard from '../MainPage/MainCard'
import { useContext, useEffect, useState } from 'react'
import ScreenScaleContext from '../../Contexts/ScreenScaleContext'
import VideoBox from './VideoBox'
import useApi from '../../hooks/useApi'
import { fetchVideos } from '../../api/fetch'
import VideoFilter from './VideoFilter'

export type VideoType = {
  title: string
  url: string
  search_tags: string
  id: number
}

const Videos = () => {
  const { smallScreenWidth } = useContext(ScreenScaleContext)
  const { data, doFetch, isLoading } = useApi(fetchVideos)
  const [filter, setFilter] = useState('Alle')
  useEffect(() => {
    doFetch({})
  }, [])
  const unfilteredVideos = (data?.videos || []) as VideoType[]
  const videos = unfilteredVideos.filter(item =>
    filter === 'Alle' ? true : item.search_tags.includes(filter),
  )
  const tags = [
    ...new Set(
      unfilteredVideos.flatMap(video =>
        !!video.search_tags ? video.search_tags.split(',') : [],
      ),
    ),
  ]
  return (
    <MainCard
      cardHeader={
        <CardHeader
          title={
            <Grid container>
              <TopLogoCard
                sx={{ width: '100%' }}
                content={
                  smallScreenWidth ? 'Video-bibliotek' : 'Videobibliotek'
                }
              />
            </Grid>
          }
        />
      }
      sx={{
        width: standardWidthLarger,
        marginBottom: 10,
      }}>
      <Grid
        container
        justifyContent='center'
        gap={1}
        sx={{
          paddingLeft: standardPadding,
          paddingRight: standardPadding,
          paddingBottom: standardPadding,
        }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <VideoFilter tags={tags} filter={filter} setFilter={setFilter} />
            {videos.map((video, key) => (
              <VideoBox video={video} key={key} />
            ))}
          </>
        )}
      </Grid>
    </MainCard>
  )
}

export default Videos
