import { Button, CardHeader, Grid } from '@mui/material'
import StrongAndSteadyInfo from '../MainPage/Workouts/StrongAndSteadyInfo'
import ShoulderAndKneesInfo from '../MainPage/Workouts/ShoulderAndKneesInfo'
import MainCard from '../MainPage/MainCard'
import {
  buttonSuccessStyle,
  standardWidthLarger,
} from '../../themes/generalStyles'
import TopLogoCard from '../MainPage/TopLogoCard'
import { useContext } from 'react'
import screenScaleContext from '../../Contexts/ScreenScaleContext'
import sessionNames from '../../constants'
import HeaderLine from '../../Utils/HeaderLine'
import config from '../../config'
import Link from '../../Utils/Link'
import userContext from '../../Contexts/UserContext'
import modalContext from '../../Contexts/ModalContext'

const WorkoutInfo = () => {
  const { loggedIn, userData } = useContext(userContext)
  const { setOpenModal } = useContext(modalContext)
  const { smallScreenWidth } = useContext(screenScaleContext)
  const paidUser = !!(loggedIn && userData && userData.validSubscription)
  return (
    <MainCard
      cardHeader={
        <CardHeader
          title={
            <Grid container>
              <TopLogoCard
                backToButton='/'
                sx={{ width: '100%' }}
                content={
                  smallScreenWidth
                    ? 'Om trenings-tilbudet'
                    : 'Om treningstilbudet'
                }
              />
            </Grid>
          }
        />
      }
      sx={{ maxWidth: standardWidthLarger, marginBottom: 10 }}>
      <>
        <MainCard>
          <div style={{ padding: 20 }}>
            <div>
              iSteady tilbyr interaktive gruppetimer med faste tidspunkter hver
              uke, som vist i oversikten nedenfor. Gruppene ledes av
              instruktører i sanntid, som gir deg muligheten til å følge
              demonstrasjoner, motta veiledning og få korrigeringer underveis.
            </div>
            <br />
            <div>
              Dette gir en mer engasjerende og strukturert treningsopplevelse,
              selv når du trener hjemmefra. Som deltaker ser du kun instruktøren
              og deg selv, mens instruktøren ser alle deltakerne.
            </div>
            <br />
            <div>
              I tillegg har vi et bibliotek med ferdiginnspilte treningsvideoer
              og mer, som du kan bruke når det passer deg.
            </div>
          </div>
        </MainCard>
        <Grid container justifyContent='center' sx={{ marginTop: 2 }}>
          <h2 style={{ textAlign: 'center' }}>
            Oversikt over interaktive gruppetimer med instruktør
          </h2>
        </Grid>
        <Grid container justifyContent='center' gap={2}>
          <StrongAndSteadyInfo />
          <ShoulderAndKneesInfo
            selectedWorkout={sessionNames['strongShoulders']}
          />
          <ShoulderAndKneesInfo selectedWorkout={sessionNames['strongKnees']} />
        </Grid>
        <HeaderLine styles={{ marginTop: 5, marginBottom: 5 }} />
        <MainCard>
          <div>
            <Grid container justifyContent='center'>
              <h2 style={{ textAlign: 'center' }}>
                Tidspunkter for våre interaktive gruppetimer
              </h2>
            </Grid>
            <Grid container justifyContent='space-around' gap={1}>
              <Grid item xs={5.5}>
                <Grid
                  container
                  columns={1}
                  direction='column'
                  gap={2}
                  alignItems='center'>
                  <Grid item>
                    <h2>
                      <strong>Mandag</strong>
                    </h2>
                  </Grid>
                  <Grid item>
                    <img
                      style={{ width: '100%' }}
                      src={`${config.bucket}/root_images/https://isteadyqabucket.ams3.cdn.digitaloceanspaces.com/root_images/mandags_program.png`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5.5}>
                <Grid
                  container
                  columns={1}
                  direction='column'
                  gap={2}
                  alignItems='center'>
                  <Grid item>
                    <h2>
                      <strong>Onsdag</strong>
                    </h2>
                  </Grid>
                  <Grid item>
                    <img
                      style={{ width: '100%' }}
                      src={`${config.bucket}/root_images/https://isteadyqabucket.ams3.cdn.digitaloceanspaces.com/root_images/onsdag_program.png`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </MainCard>
        <HeaderLine styles={{ marginTop: 5, marginBottom: 5 }} />
        <MainCard>
          <div>
            <Grid container direction='column' alignItems='center' gap={3}>
              <h2 style={{ textAlign: 'center' }}>
                Ferdiginnspilte Treningsvideoer
              </h2>
              <div>
                Vi har også ferdiginspilte videoer av våre gruppetimer og mer
                som du kan se når det passer deg.
              </div>
              <div>
                <Link to={paidUser ? '../videos' : ''}>
                  <Button
                    sx={{ ...buttonSuccessStyle }}
                    onClick={() =>
                      !paidUser ? setOpenModal('subscriptions') : null
                    }>
                    Gå til videobibliotek
                  </Button>
                </Link>
              </div>
            </Grid>
          </div>
        </MainCard>
      </>
    </MainCard>
  )
}

export default WorkoutInfo
