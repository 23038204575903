import MainCard from '../MainCard'
import HeaderLine from '../../../Utils/HeaderLine'
import { Button, Grid } from '@mui/material'
import { useContext } from 'react'
import screenScaleContext from '../../../Contexts/ScreenScaleContext'
import Center from '../../../Utils/Center'
import { buttonSuccessStyle } from '../../../themes/generalStyles'
import Link from '../../../Utils/Link'
import VideoBox from '../../Videos/VideoBox'
import settings from '../../../config'

const WhatIsCard = () => {
  const { smallScreenWidth } = useContext(screenScaleContext)
  const listMargin = 20
  return (
    <MainCard noPadding>
      <Grid
        container
        justifyContent='center'
        style={
          smallScreenWidth ? { padding: 30 } : { padding: 50, paddingTop: 30 }
        }>
        <h1
          style={{
            marginBottom: 0,
            marginTop: 0,
            textAlign: 'center',
          }}>
          Hva er iSteady?
        </h1>
        <HeaderLine styles={{ width: '100%', height: 4, marginBottom: 1 }} />
        <div
          style={{
            marginTop: 10,
          }}>
          <Center>
            <VideoBox
              invisibleBox
              light={
                <img
                  alt='thumb-about-us'
                  src={`${settings.bucket}/videos/frontVideoThumb.jpg`}
                  style={{
                    width: '100%',
                    height: 'auto',
                    cursor: 'pointer',
                    borderRadius: 5,
                  }}
                />
              }
              video={{
                url: 'https://vimeo.com/853102268',
                title: 'Hva er iSteady?',
                search_tags: '',
                id: 0,
              }}
            />
          </Center>
          {smallScreenWidth && (
            <Center sx={{ marginTop: 2 }}>
              <Link to='/ny-bruker'>
                <Button sx={buttonSuccessStyle}>Bli medlem</Button>
              </Link>
            </Center>
          )}
          <div style={{ marginTop: 20 }}>
            iSteady er et norsk online treningstilbud tilpasset deg som senior.
            Vårt mål er bedring/vedlikehold av din helse slik at du fungerer
            best mulig i dagliglivet.
            <ul>
              <li>
                Du kan trene hvor som helst der du har internettilgang og en
                enhet med kamera og lyd (nettbrett, ipad eller PC).
              </li>

              <li style={{ marginTop: listMargin }}>
                Treningstimene man melder seg på skjer i sanntid og ledes av
                instruktører på faste tidspunkter hver uke.
              </li>
              <li style={{ marginTop: listMargin }}>
                Instruktøren viser og forklarer underveis. Deltakerne ser kun
                instruktøren og seg selv, mens instruktøren ser alle som deltar.
              </li>
              <li style={{ marginTop: listMargin }}>
                Vi har også ferdiginspilte treningsvideoer som du kan se når det
                passer deg.
              </li>
            </ul>
            <strong>Få 30 dager gratis prøveperiode som ny kunde</strong>
          </div>
        </div>
      </Grid>
    </MainCard>
  )
}

export default WhatIsCard
